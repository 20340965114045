@mixin mp-0 {
  margin: 0;
  padding: 0;
}

@mixin nav-ul {
  width: 3.5em;
  height: 100vh;
  position: fixed;

  ul {
    @include mp-0;
    flex-direction: column;
  }

  // line separator
  div {
    height: 40px;

    .vline {
      border-right: 1px dashed $dgrey;
      width: 49%;
    }
  }
}
