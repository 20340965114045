@import url("https://fonts.googleapis.com/css2?family=Oleo+Script&family=Open+Sans:ital,wght@0,300;0,400;0,600;1,700&family=Roboto:wght@100;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

// dark mode
$white: #e3e3e3;
$mutedgrey: #b1b1b1;
$dgrey: #4b4f56;
$yellow: #ffd300;
$red: #d22635;
$pink: #f0346a;
$green: #2fcf7e;
$orange: #df642a;
$blue: #1499ff;
$teal: #00b8a8;
$purple: #a15def;
$bgmain: #1a1d22;
$bgdark: #131519;

$opensans: "Open Sans", sans-serif;
$roboto: "Roboto", sans-serif;
$oleo: "Oleo Script", cursive;
$montserrat: "Montserrat", sans-serif;

.white {
  color: $white;
}

.mutedgrey {
  color: $mutedgrey;
}

.yellow {
  color: $yellow;
}

.green {
  color: $green;
}

.pink {
  color: $pink;
}

.purple {
  color: $purple;
}

.orange {
  color: $orange;
}

.blue {
  color: $blue;
}

.teal {
  color: $teal;
}
