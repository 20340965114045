@import "./variables.scss";
@import "./mixins.scss";

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

body {
  @include mp-0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  background-color: $bgmain;
  color: $white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $montserrat;
  font-weight: 700;
  margin: 10px 0 10px 0;
}

a {
  text-decoration: none;
  color: $white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: 14px;
  padding-left: 3px;
  color: $mutedgrey;

  span {
    color: $yellow;
  }
}

.name {
  font-size: 80px;
  color: $pink;
}
h1 {
  font-size: 60px;
}

h2 {
  font-size: 50px;
}

h3 {
  font-size: 44px;
  font-weight: 400;
  color: $green;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 22px;
  font-weight: 200;
}

p {
  color: $mutedgrey;
  line-height: 1.2;
  font-size: 16px;
}

.main {
  margin: 0 0 0 3.5em;
  transition: background 1.5s 0.2s ease-in-out
    cubic-bezier(0.51, -0.24, 0.18, 1.28);
  width: calc(100vw - 3.5em);
  height: 100vh;
}

.flex {
  display: flex;
}

.container {
  margin: auto;
  padding: 20px;
  width: 800px;

  h3:last-of-type {
    color: $green;
  }
}

.page {
  height: 70vh;
  width: 80vw;
  margin-right: 5.5em;
}

.container and .flex {
  justify-content: center;
}

.height-full {
  height: 100vh;
}

.scroll {
  padding: 10px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.heading {
  text-align: left;
}

// NAVBAR
nav {
  @include nav-ul;
  background-color: $bgdark;
  justify-content: center;

  ul {
    align-self: center;
  }

  a {
    font-size: 1.2em;
    margin: 10px auto;
    font-family: $montserrat;
    transition: ease 0.6s;
    cursor: pointer;

    svg {
      font-size: 1.2em;
    }

    &:hover {
      &:first-child {
        font-size: 1.25em;
        color: lighten($color: $orange, $amount: 28%);
      }

      &:nth-of-type(2) {
        font-size: 1.25em;
        color: lighten($color: $yellow, $amount: 28%);
      }

      &:nth-of-type(3) {
        font-size: 1.25em;
        color: lighten($color: $green, $amount: 28%);
      }

      &:nth-of-type(4) {
        font-size: 1.25em;
        color: lighten($color: $teal, $amount: 28%);
      }

      &:last-child {
        font-size: 1.25em;
        color: lighten($color: $blue, $amount: 28%);
      }
    }
  }

  .active {
    font-size: 1.8em;
    margin: 20px auto;
    transition: ease 0.6s;

    &:hover {
      font-size: 1.8em !important;
      cursor: none;
    }

    &:first-child {
      color: $orange;
    }

    &:nth-of-type(2) {
      color: $yellow;
    }

    &:nth-of-type(3) {
      color: $green;
    }

    &:nth-of-type(4) {
      color: $teal;
    }

    &:last-child {
      color: $blue;
    }
  }
}

div .nav-right {
  @include nav-ul;
  right: 0;
  color: $dgrey;

  ul {
    width: inherit;
    align-self: flex-end;
    justify-content: center;
    margin-bottom: 2em;

    .email {
      transform: rotate(90deg);
      list-style: none;
      font-size: 12px;

      &:hover {
        transition: ease 0.6s;
        color: lighten($color: $dgrey, $amount: 30%);
        cursor: none;
      }
    }

    a {
      @include mp-0;
      font-size: 24px;
      align-self: center;
      margin: 12px auto;
      color: inherit;

      &:hover {
        transition: ease 0.4s;
        color: lighten($color: $dgrey, $amount: 50%);
        cursor: none;
      }

      &:first-of-type {
        margin-top: 160px;
      }
    }

    div {
      height: 50px;
      padding-top: 10px;
    }
  }
}

.card-container {
  width: 350px;
  height: 380px;
  margin: 20px;
  transition: ease-in-out 0.8s;

  &:last-of-type {
    padding-right: 50px;
  }

  &:hover {
    p,
    li {
      color: $bgmain;
    }

    .card {
      transition: ease-in-out 0.8s;
      top: -5px;
      left: -5px;
      border: 3px solid $pink;
      background-color: rgba(242, 145, 173, 0.8);
    }

    .shadow {
      transition: ease-in-out 0.8s;
      bottom: 330px;
      left: 20px;
      border: 1px solid $dgrey;
      background-color: $bgdark;
    }
  }
}

.card {
  position: relative;
  top: 0;
  left: 0;
  border: 1px solid $pink;
  border-radius: 5px;
  width: 300px;
  height: 350px;
  z-index: 10;
  background-color: $bgdark;
}

.shadow {
  margin-right: 20px;
  z-index: 3;
  width: 300px;
  height: 350px;
  border: 3px solid lighten($color: $pink, $amount: 10%);
  border-radius: 5px;
  position: relative;
  bottom: 340px;
  left: 15px;
}

.card-body {
  padding: 6px 14px;

  p {
    font-size: 14px;
  }

  li {
    font-size: 14px;
    list-style: circle;
  }

  h6 {
    font-size: 16px;
    margin: 12px auto 5px auto;
  }
}

.card-header {
  height: 40px;
  justify-content: space-between;
  align-content: center;

  h5 {
    font-size: 20px;
    margin: auto 0px;
  }

  .links {
    justify-content: space-evenly;
    a {
      font-size: 24px;
      margin: auto 5px;

      &:hover {
        transition: ease-in-out 0.6s;
        color: $bgdark;
      }
    }
  }
}

.pageno {
  font-size: 20px;
  font-weight: 100;
}

.tech {
  margin-top: 30px;

  h5 {
    color: $teal;
  }
}

.about {
  justify-content: space-between;
  align-content: flex-end;

  h1 {
    align-self: flex-end;
  }

  .profile {
    z-index: 10;
    background: cover;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    filter: opacity(80%) grayscale(50%);

    &:hover {
      transition: ease-in-out 0.6s;
      filter: none;
    }
  }
}

.skills {
  width: inherit;
  align-content: center;
  display: flex;
  flex-wrap: wrap;

  div {
    width: 100px;
    padding: 5px;
    display: flex;
    justify-content: center;
    margin: 5px 20px;
    transition: ease 0.6s;
    color: $dgrey;

    &:hover {
      cursor: none;
      code {
        color: $white;
      }

      .fa-js-square {
        transition: ease 1s;
        color: $yellow;
      }

      .fa-react {
        transition: ease 1s;
        color: $teal;
      }

      .fa-bootstrap {
        transition: ease 1s;
        color: $purple;
      }

      .fa-node-js {
        transition: ease 1s;
        color: $green;
      }

      .fa-leaf {
        transition: ease 1s;
        color: $green;
      }

      .fa-html5 {
        transition: ease 1s;
        color: $orange;
      }

      .fa-css3 {
        transition: ease 1s;
        color: $blue;
      }

      .fa-database {
        transition: ease 1s;
        color: $purple;
      }

      .fa-python {
        transition: ease 1s;
        color: $teal;
      }
    }
  }

  svg {
    font-size: 16px;
    margin-right: 5px;
  }
}

.footer {
  position: absolute;
  bottom: 10px;
  left: 5em;

  p {
    font-size: 10px;
    margin: 2px auto 2px auto;
  }
}

.contact {
  text-align: center;

  p {
    margin-top: 40px;
    margin-bottom: 30px;
  }
}

.button {
  margin: 0 auto;
  width: 140px;
  padding: 14px;
  border: 1px solid $pink;
  border-radius: 5px;

  &:hover {
    transition: ease 0.8s;
    color: white;
    background-color: rgba(240, 52, 106, 0.4);
  }
}

@media screen and (max-width: 768px) {
  body {
    height: device-height;
  }

  nav {
    height: device-height;
  }

  .name {
    font-size: 50px;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 34px;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 20px;
  }

  p {
    font-size: 12px;
  }

  .about {
    .profile {
      width: 80px;
      height: 80px;
    }
  }

  .container {
    padding: 20px 50px 20px 20px;
  }

  .skills {
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
    display: block;

    div {
      justify-content: left;
      code {
        font-size: 12px;
      }
    }
  }

  .scroll {
    width: 73vw;
    padding: 20px 0px 20px 0px;
  }

  .card-container {
    width: 250px;
    margin: 0px 30px 0 0;

    &:hover {
      .card {
        transition: ease-in-out 0.8s;
        top: -5px;
        border: 3px solid $pink;
        background-color: rgba(242, 145, 173, 0.8);
      }

      .shadow {
        transition: ease-in-out 0.8s;
        bottom: 288px;
        left: 15px;
        border: 1px solid $dgrey;
        background-color: $bgdark;
      }
    }
  }

  .card-header {
    text-align: center;
    font-size: 18px;
    display: block;

    .links {
      margin-top: 10px;
    }
  }

  .card {
    width: 250px;
    height: 300px;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-bottom: 10px;
  }

  .shadow {
    width: 250px;
    height: 300px;
    bottom: 298px;
    left: 5px;
  }

  .card-body {
    p {
      font-size: 12px;
      margin-top: 50px;
    }

    li {
      font-size: 12px;
    }
  }
}

#scroll-icon {
  margin-top: 15vh;
  display: flex;
  justify-content: center;
  animation: bounceAlpha 1.8s linear infinite;
}

#rotate {
  animation: rotate360 4s linear infinite;
}

@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateY(10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateY(-10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

@keyframes rotate360 {
  0% {
    transform: rotateZ(0deg);
  }
  25% {
    transform: rotateZ(90deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  75% {
    transform: rotateZ(270deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}